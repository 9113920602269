import styled from 'styled-components';

export const Container = styled.header`
    background: var(--shape) !important;
    border-bottom: 1px solid var(--border);
`;

export const Content = styled.div`
    max-width: 853px;
    margin: 0 auto;
    padding: 1rem;

    h1 {
        text-align: center;
        margin: 1rem 0 1.5rem;

        @media (max-width: 767px) {
            font-size: 1.6rem;
            margin: 0 0 1rem;
        }
    }
`;

export const ArticleWrapper = styled.div`

    margin-top: 1.5rem;
    line-height: 2;

    p {
        margin-bottom: 1rem;
        text-align: justify;
        font-size: 1.2rem;
        font-weight: 300;
    }

    h3 {
        font-size: 1.3rem;
    }

    ul, ol {
        padding-left: 1.5rem;
        margin-bottom: 1rem;
    }

    li {
        margin-bottom: 0.5rem;
        text-align: justify;
        font-size: 1.2rem;
        font-weight: 300;
    }

    a {
        color: #6597f1;
        text-decoration: none;
    }

    .hero img {
        border: 1px solid #cccccc;
        border-radius: 5px;
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .indent {
        padding-left: 1.5rem;
    };
    
    table {
        border-collapse: separate;
        border-spacing: 0;
        margin-bottom: 1.5rem;
        margin-top: .25rem;
        width: 100%;
        text-align: left;
        font-size: 1.2rem;
        font-weight: 300;

        th {
            background-color: #e5e5e5;
            border-left: 1px solid #c3c3c3;
            border-bottom: 1px solid #c3c3c3;
            padding: .25rem .75rem;

            &:first-child {
                border-top-left-radius: .375rem;
            }

            &:last-child {
                border-top-right-radius: .375rem;
            }
        }

        td {
            border-left: 1px solid #c3c3c3;
            border-bottom: 1px solid #c3c3c3;
            padding: .25rem .75rem;
        }

        thead tr:last-child th {
            border-top: 1px solid #c3c3c3;
        }

        thead tr:last-child th:last-child,
        tbody tr td:last-child {
            border-right: 1px solid #c3c3c3;
        }

        tbody tr:last-child td:first-child {
            border-bottom-left-radius: .375rem;
        }

        tbody tr:last-child td:last-child {
            border-bottom-right-radius: .375rem;
        }
    }

    .text-left {
        text-align: left !important;
    }

    .text-center {
        text-align: center;
    }
    
    b {
        font-weight: 600;
    }

    @media (max-width: 720px) {
        
        h2 {
            font-size: 1.2rem;
        }
        h3 {
            font-size: 1.1rem;
        }
        p, li, table {
            font-size: 1rem;
        }
    }
`;
